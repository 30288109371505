//page template document
.document {
    flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 6em 0 0;
    @include max-screen ($bp-t) {
    	margin: 1em auto;
    	p {
			width: 780px;
			margin: auto;
    	}
    }
    @include max-screen ($bp-m) {
        margin-top: 1em;
    }
}
.document-p {
    @include max-screen ($bp-m) {
        font-size: 1.6rem;
    }
}