//single content
.single_bold {
	font-weight: bold;
}
.single_main-wrap {
    margin-top: 3em;
    padding: 0 3em;
    p {
		font-size: 1.8rem;
		line-height: 1.6;
		margin-bottom: 1.5em;
		margin-top: 1.5em;
    }
	h1 {
		@extend .single_bold;
		line-height: 1.3;
		padding: .5em .5em;
		border-bottom: 1px solid;
		font-size: 2.8rem;
	}
	h2 {
		@extend .single_bold;
		line-height: 1.25;
		margin-top: 1.5em;
		font-size: 2.4rem;
	}
	h3 {
		@extend .single_bold;
		line-height: 1.25;
		margin-bottom: .8em;
		font-size: 2.4rem;
	}
	h4 {
		@extend .single_bold;
		line-height: 1.4;
		font-size: 2.4rem;
		margin-bottom: 1em;
	}
	h5 {
		@extend .single_bold;
		line-height: 1.4;
		font-size: 2.4rem;
		margin-bottom: 1em;
	}
	img {
		height: auto;
		width: 100%;
		margin: 1em 0;
	}
	ul,ol {
	    font-size: 1.8rem;
	    line-height: 1.6;
	    margin: 2em 0 1em;
	    li {
	    	margin: .5em 0;
	    }
	}
	a {
		&:before {
			content: "\f0c1";
			font-family: FontAwesome;
			margin-right: .5em;
			position: relative;
			top: 1px;
		}
	}
	@include max-screen ($bp-tw) {
		padding: 0 1em;
		margin-top: 2em;
		h1{
			font-size: 2.8rem;
			margin-bottom: 1em;
		}
		h2{font-size: 2.2rem;}
		h3,h4,h5{font-size: 1.9rem;}
		ul,ol{
			margin:.5em 0;
			font-size: 1.4rem;
			line-height: 1.3;
		}
		p {
			margin-top: 0;
			padding: 0;
			font-size: 1.4rem;
			font-weight: normal;
		}
	}
	@include max-screen ($bp-m) {
		h1{
			font-size: 2.4rem;
			margin-bottom: 1em;
			padding-left: 0;
			padding-right: 0;
		}
	}
}
.extend_post_feed {
	color: #fff;
	font-size: 1.8rem;
	height: 12em!important;
	overflow: hidden;
	transition: all .2s ease-in-out;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		filter: blur(2px);
		background: inherit;
		transform: scale(1.2);
	}
	&:hover {
		opacity: .8;
	}
	@include max-screen ($bp-tw) {
		height: 7em!important;
	}
}
.post_prev {
	@extend .extend_post_feed;
	@include max-screen ($bp-m) {
	}
}
.post_next {
	@extend .extend_post_feed;
}
.post_nav {
	font-size: 1.4rem;
}
.prev-box-inner_l {
	width: 100%;
	padding-left: 2em;
	position: relative;
	text-shadow: 1px 1px 3px #333;
	line-height: 1.5;
	@include max-screen ($bp-tw) {
		width: 100%;
		padding: 0;
	}
}
.prev-box-inner_r {
	width: 100%;
	padding-right: 2em;
	text-align: right;
	position: relative;
	text-shadow: 1px 1px 1px #333;
	line-height: 1.5;
	@include max-screen ($bp-tw) {
		padding: 0;
	}
}
.post_nav-title {
    margin-top: .5em;
	@include max-screen ($bp-tw) {
		font-size: 1.4rem;
	}
}
.article-feed {
    margin-top: 7em;
}
.wp-caption {
	margin: 2.5em auto;
}
.wp-caption-text {
    padding: .5em 0!important;
    color: #868686!important;
    margin: -1em 0 0!important;
    font-size: 1.4rem!important;
}
.single_content {
	blockquote {
		p {
			margin-top: 0;
		}
	}
}
