//common parts breadcrumb
.bc-container {
    height: 100%;
    position: relative;
    @include max-screen ($bp-tw) {
        width: 100%;
    }
}
.breadcrumb {
    padding: 0;
    position: absolute;
    bottom: .5em;
    color: #fff;
    z-index: 2;
    font-size: 1.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    li {
        transition: all .2s ease-in-out;
        &:hover {
            background-color: $theme-color;
            &:last-child {
                background: none;
            }
        }
    }
    a {
        color: #fff;
    }
    @include max-screen ($bp-tw) {
        bottom: .5em;
        font-size: 1.1rem;
        width: 100%;
        left: 0;
        padding-left: 1em;
    }
}
i.fa.fa-home {
    color: #fff;
    font-size: 1.9rem;
    position: relative;
    top: 2px;
    margin-right: .5rem;
}
.breadcrumb_mb {
    background: $theme-color;
    position: relative;
    margin-top: -1em;
    line-height: 2.6;
    margin-bottom: 1em;
    .breadcrumb {
        position: static;
        text-align: initial;
        padding: 0;
    }
}
//