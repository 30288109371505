//top mainvisual
.main-visual-wrap {
	position: relative;
	overflow: hidden;
	a:hover {}
}
.main-visual-text {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	text-align: center;
	color: #fff;
	letter-spacing: 3px;
	text-shadow: 4px 2px 6px rgb(0, 0, 0);
	@include max-screen ($bp-m) {
		bottom: .25em;
	}
}
.down {
	width: 50px;
	height: 50px;
	border: 1px solid #ffd73f;
	margin: .5em auto;
	border-radius: 50%;
	display: block;
	color: #ffd73f;
	&:hover {
		background: #ffd73f;
		color: #fff;
	}
	.top_down {
		position: relative;
		top: 9px;
		left: 1px;
		font-size: 3rem;
	}
}

