//common header title
.top_logo_title {
	display: block;
	text-align: center;
	color: $theme-color;
	font-family: $theme-fonts_mincho;
	font-size: 4rem;
	margin: 0;
	@include max-screen($bp-m) {
		font-size: 2rem;
		font-weight: normal;
		padding: .5em 0;
	}
}
.page_bg-title {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
    h1 {
		font-family: $theme-fonts_mincho;
		color: #fff;
		margin-bottom: 1.6em;
		margin-top: 1.6em;
		font-weight: bold;
		text-align: center;
		z-index: 1;

	}
	&:before {
		content: '';
		position: absolute;
		top: 0px;
		left: 0;
		width: 100%;
		height: 100%;
		filter: blur(71px);
		-webkit-filter: blur(29px);
		background: inherit;
		filter: blur(5px);
		transform: scale(1.2);
	}
    @include max-screen($bp-m) {
		height: 16em;
		margin-bottom: 1em;
	}
}