//page template products powder
.powder {
	display: flex;
	align-items: center;
	text-align: left!important;
	margin-bottom: 2em;
	img {
		width: 100%;
	}
	@include max-screen ($bp-m) {
		margin-bottom: 1em;
		padding: 1em 0;
		border-bottom: 1px dotted #f3f3f3;
		img {
			margin-bottom: 0!important;
		}
	}
}
.powder-l {
	flex-basis: 24%;
	margin-right: 5%;
}
.powder-r {
    flex-basis: 71%;
    p {
    	line-height: 1.6;
    }
    h4 {
    	margin-bottom: .25em;
    }
	@include max-screen ($bp-m) {
		p {
			line-height: 1.4;
		}
	}
}