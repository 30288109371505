//page template company
.page-desc {
	text-align: center;
	line-height: 2;
	margin-bottom: 4em;
	margin-top: 4em;
	@include max-screen ($bp-m) {
		font-size: 1.4rem;
		line-height: 1.6;
	}
}
.table-common{
	vertical-align: middle!important;
	height: 5em;
	line-height: 2em!important;
}
.company_ul {
	padding: 2em 3em;
	@include max-screen ($bp-m) {
		margin: 1em auto;
		padding: 0px 0 0 2em;
		li {
			font-size: 1.6rem;
			line-height: 1.6;
			margin-bottom: 1em;
		}
	}
}
.company_ul-inner {
	margin: 1em auto;
	li {
		margin-bottom: 1em;
		font-size: 1.8rem;
	}
	@include max-screen ($bp-m) {
		margin: 0;
		li {
			font-size: 1.4rem;
			margin-bottom: .5em;
		}
	}
}
.table_column2 {
	margin-bottom: 7em;
	th {
		@extend .table-common;
		width: 40%;
		text-align: center;
		background-color: #f9f9f9;
	}
	td {
		@extend .table-common;
		padding-left: 3em!important;
		padding: 2em 1em 2em 2em !important;
		line-height: 1.5!important;
	}
	@include max-screen ($bp-t) {
		th,td {
			height: auto;
		}
	}
	@include max-screen ($bp-m) {
		margin-bottom: 3em;
		font-size: 1.4rem;
		th,td {
			display: block;
			width: 100%;
			height: auto;
			padding: 1rem!important;
		}
	}
}

.company_effort {
	margin-bottom: 2em;
	h2 {
		font-size: 2rem;
		background: #eee;
		padding: 1em;
		margin: 0;
		text-align: center;
	}
	@include max-screen ($bp-m) {
		margin-bottom: 0;
		h2 {
			padding: .5em;
			line-height: 1.6;
			text-align: left;
		}
	}
}
.effort-inner {
    justify-content: space-between;
    align-items: stretch;
    @include max-screen ($bp-m) {
    	flex-wrap: wrap;
    }
}
.inner-box {
	flex-basis: 50%;
	margin: 1em -1em;
	@include max-screen ($bp-t) {
		flex-basis: 49%;
		margin: 0;
	}
	@include max-screen ($bp-m) {
		flex-basis: 100%;
		margin: 0;
	}
}
.effort-inner-left {
	@extend .inner-box;
}
.effort-inner-right {
    @extend .inner-box;
}
.correlation-diagram {
	margin-bottom: 6em;
	box-shadow: 0px 0px 37px #cecece;
	@include max-screen ($bp-m) {
		margin-bottom: 2em;
	}
}