//common parts button
.btn_frame {
    display: block;
    padding: 1.4em 1em;
    text-align: center;
    border-radius: .3rem;
    font-size: 1.8rem;
}
.main-btn {
	background: $theme-color_accent;
	color: #fff;
	width: 320px;
    padding: 1em;
    margin: 3em auto;
	&:hover {
		background: darken($theme-color_accent,25%);
		color: #fff;
	}
    @include max-screen ($bp-tw) {
        padding: .55em;
    }
    @include max-screen ($bp-m) {
        margin: 1em auto;
        width: 100%;
        padding: .75em;
        font-size: 1.6rem;
    }
}
.btn_table-inner {
    display: block;
    width: 17em;
    background: $theme-color_accent;
    color: #fff;
    text-align: center;
    padding: .5em 1em;
    margin-top: 1em;
    border-radius: 2px;
    &:hover {
        background-color: darken($theme-color_accent, 10%);
        color: #fff;
    }
    @include max-screen ($bp-m) {
        width: 100%;
        margin-bottom: 1em;
    }
}
.button {
    margin: 10px;
    padding: 20px;
    border: 2px solid #f7f7f7;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    overflow:hidden;
    transition: .3s;
    color: #fff;
    width: 17em;
    &:after {
        position: absolute;
        transition: .3s;
        content: '';
        width: 0;
        left: 50%;
        bottom: 0;
        height: 3px;
        background: #f7f7f7;
    }
    &:hover {
        cursor: pointer;
        border-radius: 40px;
        color: #fff;
        background-color: darken($theme-color,5%);
        &:after {
            width: 100%;
            left: 0;
        }
        &:after {
            width: 0%;
        }
    }
    @include max-screen ($bp-m) {
        font-size: 1.6rem;
        padding: 1em 1.5em;
    }
}

//fixed menu buton
.btn_menu {
    position: absolute;
    right: 0;
    bottom: 6px;
    font-size: 4rem;
    font-weight: 200;
    z-index: 99998;
    background-color: #fff;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    span {
        display: block;
        font-size: 1.25rem;
        margin-top: -1.75em;
    }
    @include max-screen($bp-tw) {
        right: 0;
        transition: initial;
    }
    @include max-screen ($bp-t) {
        top: -17px;
        right: 2px;
    }
    @include max-screen ($bp-m) {
        top: 0;
        right: -14px;
        width: 54px;
        height: 54px;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.79);
        position: relative;
        &:hover {
            background-color: #fff;
        }
        i.fa.fa-bars {
            font-size: .6em!important;
            position: relative;
            top: -4px;
        }
        span {
            position: relative;
            top: -24px;
            margin-top: 0;
            font-size: 7px;
        }
    }
}
.menu_close {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    top: 0;
    left: 0;
    color: #fff;
    font-size: 10rem;
    display: none;
    z-index: 3;
}
.menu_close-inner {
    display: block;
    position: fixed;
    top: 32%;
    right: 5%;
    z-index: 2;
    span {
        margin-left: .25em;
    }
    @include max-screen ($bp-tw) {
        right: 2%;
        font-size: 8rem;
    }
    @include max-screen ($bp-m) {
        font-size: 6rem;
        span {
            display: none;
        }
    }
}
.btn_menu-inner {
    position: relative;
    top: -7px;
    i.fa.fa-bars {
        position: relative;
        font-size: 3.4rem;
    }
    @include max-screen ($bp-m) {
        top: -16px;
        right: 6px;
    }
}
//

.btn_in-cardlayout {
    display: block;
    text-align: center;
    background: #212a40;;
    padding: 1em;
    color: #fff;
    border-radius: 2px;
    font-size: 2rem;
    &:hover {
        background-color: #f3f3f3;
    }
    .fa {
        margin-right: .5em;
    }
    @include max-screen ($bp-m) {
        font-size: 1.5rem;
        padding: .5em;
    }
}

//button fix contact
.btn_contact {
    position: fixed;
    right: 1em;
    bottom: 1em;
    background-color: $theme-color_accent;
    padding: 1em;
    color: #fff;
    z-index: 999;
    border-radius: 2px;
    transition: all .4s ease-in-out;
    &:hover {
        background-color: darken($theme-color_accent,10%);
        color: #fff;
    }
    @include max-screen ($bp-tw) {
        right: 1em;
        bottom: 1em;
    }
    @include max-screen ($bp-m) {
        right: 0;
        bottom: 0em;
        text-align: center;
        border-radius: 0;
        width: 100%;
    }
}