//common header common
.top_logo_sub-tt {
	text-align: center;
	font-size: 1.6rem;
	font-weight: normal;
    margin-top: 0em;
	@include max-screen($bp-m) {
		font-size: 1.1rem;
		line-height: 1.4;
		margin-bottom: .5em;
	}
}

.sticky-nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	display: none;
	z-index: 9999;
	box-shadow: 0 0 0 4px #eee;
	nav {
		width: 75%;
	}
	ul {
		display: flex;
		justify-content: center;
		align-aitem: center;
		list-style: none;
		padding: 0;
		margin-bottom: 0;
		li {
			a {
				display: block;
				padding: 1em;
				color: #333;
				&:hover {
					background-color: #eee;
				}
			}
		}
	}
	@include max-screen($bp-tw) {
		font-size: 1.4rem;
		padding: 1.2em 0;
	}
    @include max-screen($bp-t) {
        padding: 2.2em 0;
    }
    @include max-screen($bp-m) {
        padding: 0;
    }
}
.inner {
	position: relative;
}
.sticky_logo {
    width: 50%;
    font-size: 3rem;
    text-align: center;
    padding: 0 1em;
    font-family: $theme-fonts_mincho;
    transition: initial;
    @include max-screen($bp-tw) {
    	padding: 0;
    	margin-right: 1em;
    }
	@include max-screen($bp-t) {
		width: 100%;
		font-size: 2.4rem;
	}
    @include max-screen($bp-m) {
    	width: 100%;
    	padding: 0 1em;
    	font-size: 1.8rem;
        margin-right: 0;
    }
}
//page-header
.header {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background-position: center;
}

.header-title {
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 60%;
    padding: 30px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    border: 3px solid #fff;
    border-bottom: none;
    text-shadow: 2px 2px 4px #191717;
    p {
        -ms-transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        font-style: oblique;
        font-size: 1.2em;
        color: #fff;
        overflow: hidden;
        margin: -30px -33px 0 -33px;
        position: absolute;
        width: calc( 100% + 6px);
        bottom: -37px;
    }
    @include max-screen ($bp-t) {
		min-width: 80%;
    }
    @include max-screen ($bp-m) {
        width: 92%;
        margin: 0;
        p {
            font-size: 1em;
            bottom: -27px;
        }
    }
}

.header-title .page-title,
.header-title p {
  letter-spacing: 0.05em;
}

.header-title .page-title {
    font-size: 4rem;
    line-height: 1.6;
    text-transform: uppercase;
    margin: 1.5rem;
    letter-spacing: 6px;
    @include max-screen ($bp-m) {
        font-size: 2rem;
        margin: 0;
        letter-spacing: 2px;
    }
}

.header-title p:before,
.header-title p:after {
  content: "";
  position: absolute;
  width: 100%;
  margin-top: -2px;
  border-top: 3px solid;
  top: 50%;
}

.header-title p:before {
  -ms-transform: translate(-100%, 0);
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  margin-left: -30px;
}

.header-title p:after {
  margin-left: 30px;
}

.title .page-title {
  padding: 20px;
}
.page-title {
    font-family: $theme-fonts_mincho;
    color: #fff;
}

#top_logo {
    position: relative;
    z-index: 1;
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.29);
}

