//page template privacy policy
.pp-big-title {
	position: relative;
	margin: 2em;
	text-align: center;
	font-weight: bold;
	&::after {
		content: "";
		width: 5em;
		height: 1px;
		background: #333;
		position: absolute;
		bottom: -2rem;
		left: 42%;
	}
	@include max-screen ($bp-m) {
		margin: 0 0 2em;
		font-size: 2.4rem;
		&::after {
			left: 30%;
		}
	}
}
.pp-common_inner {
	width: 80%;
	@include max-screen ($bp-m) {
		width: 100%;
	}
}
.pp-middle-title {
	@extend .pp-common_inner;
	margin: 0 auto 1em;
	font-weight: bold;
	border-left: 1.1em solid #333;
	padding-left: .6em;
	@include max-screen ($bp-m) {
		font-size: 1.8rem;
	}
}
.pp-small-title {
	@extend .pp-common_inner;
	margin: 0 auto 1em;
	@include max-screen ($bp-m) {
		font-size: 1.8rem;
		line-height: 1.4;
		font-weight: bold;
	}
}
.privacy-policy {
	p {
		@extend .pp-common_inner;
		margin: 0 auto 3em;
	}
	ul {
		@extend .pp-common_inner;
		margin: 0 auto 3.5em;
	}
	table {
		margin: 5em 0 0!important;
	}
	@include max-screen ($bp-tw) {
		ul {
			margin-bottom: 1.5em;
		}
	}
	@include max-screen ($bp-m) {
		ul {
			margin-bottom: 1em;
			li {
				font-size: 1.6rem;
				line-height: 1.6;
				margin-bottom: 1em;
			}
		}
		p {
			margin-bottom: 1em;
			font-size: 1.6rem;
			line-height: 1.6;
		}
	}
}
.supported_browser {
	padding: 0;
	list-style: none;
	font-size: 5rem;
	width: 80%;
	text-align: center;
	margin: 0 auto 3.5em;
	border: 1px solid #ccc;
	background-color: #f7f7f7;
	padding: 1em 0;
	li {
		flex-basis: 25%;
		i.fa {
			font-size: 7rem;
		}
		span {
			display: block;
			font-size: 1.3rem;
			margin-top: 1em;
		}
	}
	@include max-screen ($bp-m) {
		flex-wrap: wrap;
		padding: 0;
		li {
			flex-basis: 50%;
			margin: 1.5em 0;
			i.fa {
				font-size: 5rem;
			}
		}
	}
}