//common typography
.title_bg-border {
	position: relative;
	text-align: center;
	margin: 1em auto 2em;
	color: $theme-color;
	font-family: $theme-fonts_mincho;
	font-size: 3.4rem;
	letter-spacing: 3px;
	line-height: 1.5;
	span {
		position: relative;
		z-index: 2;
		display: inline-block;
		margin: 0 2.5em;
		padding: 0 1em;
		background-color: #fff;
		text-align: left;
	}
	&::before {
		position: absolute;
		top: 50%;
		left: 0;
		z-index: 1;
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		background-color: $theme-color;
	}
	@include max-screen ($bp-tw) {
		margin-top: 3em;
	}
	@include max-screen ($bp-t) {
		margin: 1em auto;
	}
	@include max-screen ($bp-m) {
		margin: 1em auto;
		span {
			text-align: left;
			margin: auto;
			font-size: 2.2rem;
			line-height: 1.4;
			padding: .5em;
		}
	}
}

//background img title
.bg-title_set {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.bg-title_s3_1 {
	@extend .bg-title_set;
	background-image: url(../../images/page-inner_vegetable-cultivation.jpg); 
}
.bg-title_s3 {
	@extend .bg-title_set;
	background-image: url(../../images/page_company_productbg.jpg); 
}
.bg-title_bs_1 {
	@extend .bg-title_set;
	background-image: url(../../images/bs-bg.jpg); 
}
.bg-title_yukimuro {
	@extend .bg-title_set;
	background-image: url(../../images/bs-bg.jpg); 
}