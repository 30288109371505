//common footer common
.footer-inner {
	position: relative;
	small {
		padding: 2rem 2rem 7rem;
		display: block;
	}
	@include max-screen($bp-m) {
		small {
			font-size: 1rem;
		}
	}
}
.common-footer {
	background-image: url(../../images/footer-bg.jpg); 
	background-size: cover;
	background-position: center;
	position: relative;
	color: #fff;
	text-align: center;
    &:before {
		content: '';
		background: rgba(0,0,0,.55);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.footer-title {
	font-family: $theme-fonts_mincho;
	font-size: 3.5rem;
	padding: 2em;
	span {
		display: block;
		font-size: 1.5rem;
		margin-top: 0.3em;
	}
	@include max-screen($bp-t) {
		padding: 1.5em 0 1em;
	}
	@include max-screen($bp-m) {
		padding: 1em;
		font-size: 2.4rem;
		span {
			font-size: 1.2rem;
		}
	}
}
.company-info {
	letter-spacing: 2px;
	@include max-screen($bp-m) {
		font-size: 1.2rem;
		padding: 0 1em;
	}
}
.page-top {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	cursor: pointer;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: $theme-color;
	i.fa.fa-angle-up {
		position: relative;
		left: 28%;
		top: 7%;
		font-size: 2em;
		color: #fff;
	}
	&:hover {
		transform: scale(1.2);
		background-color: darken($theme-color,15%);
	}
	@include max-screen($bp-t) {
		width: 30px;
		height: 30px;
		top: 11px;
		right: 6px;
		i.fa.fa-angle-up {
			font-size: 2rem;
			top: -2px;
			left: 8px;
		}
	}
}
.fixed-btn {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 10;
	a {
		background-color: $theme-color_accent;
		display: block;
		text-align: center;
		padding: 1em;
		color: #fff;
		width: 15em;
		&:hover {
			background-color: darken($theme-color_accent,15%);
		}
		&:first-child {
			background-color: #eee;
			color: $theme-color;
			&:hover {
				background-color: $theme-color;
				color: #eee;
			}
		}
		.fa {
			font-size: 2.8rem;
		}
	}
	@include max-screen($bp-m) {
		width: 100%;
		a {
			width: 50%;
			font-size: 1.3rem;
			.fa {
				display: none;
			}
		}
	}
}