//single common
.single_wrap {
	margin: 8em 0;
	display: flex;
	align-items: flex-start!important;
	@include max-screen ($bp-tw) {
		flex-wrap: nowrap!important;
	}
	@include max-screen ($bp-m) {
		margin: 1em 0;
		flex-wrap: wrap!important;
	}
}

.single_right {
	flex-basis: 70%;
	@include max-screen ($bp-m) {
		flex-basis: 100%;
		padding: 0;
		margin-top: 2em;
		order: 1;
	}
}
.single_left {
	padding-right: 3em;
	flex-basis: 30%;
	@include max-screen ($bp-m) {
		flex-basis: 100%;
		padding: 0;
		margin-top: 2em;
		order: 2;
	}
}