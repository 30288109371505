//common parts widget
.widget {
    list-style: none;
    margin-bottom: 4em;
    ul {
        list-style: none;
        padding: 0;
        li {
            a {
                display: block;
                color: #333;
                padding: 1em;
                font-size: 1.5rem;
                &:hover {
                    background-color: #f3f3f3;
                }
            }
        }
    }
    @include max-screen ($bp-m) {
        ul {
            li {
                a {
                    font-size: 1.4rem;
                }
            }
        }
    }
}
.widgettitle {
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: .5em;
}