//common parts latest infomation
.article-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    max-width: 1200px;
    margin: 0 auto;
    @include max-screen ($bp-m) {
    	flex-direction: column;
		margin-bottom: 2em;
    }
}
.latest-info_block {
    margin: 6em auto;
    @include max-screen($bp-tw) {
    	margin: 2em auto;
    }
    @include max-screen ($bp-t) {
    	margin: 0 auto 2em;
    }
    @include max-screen ($bp-m) {
    	margin: 1em auto 3em;
    }
}
.new-entry {
    flex-basis: 49%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 4rem;
    text-align: center;
    border: 1px solid #d4d4d4;
    transition: all .4s ease-in-out;
    &:hover {
        border-color: #333;
    }
	@include max-screen ($bp-t) {
		margin-bottom: 2rem;
	}
	@include max-screen ($bp-m) {
		border: none;
		margin: 0;
		flex-basis: inherit;
	}
}
.post-meta {
    margin-bottom: 1em;
}
.entry-box {
    display: flex;
    align-items: center;
    &:hover {
        .new-entry-thumb img{
        	transform: scale(1.1);
        }
    }
	@include max-screen ($bp-m) {
        border-bottom: 1px solid #f3f3f3;
        padding: .5em 0;
	}
}
.new-entry-thumb {
    flex-basis: 45%;
    overflow: hidden;
	img {
		width: 100%;
		transform: scale(1);
		transition: all .3s ease-in-out;
	}
	@include max-screen ($bp-t) {
		flex-basis: 40%;
	}
}
.new-entry-content {
    flex-basis: 55%;
    padding: 1em;
    h1 {
        font-size: 2rem;
        margin-bottom: 1.6rem;
        line-height: 1.6;
        color: #333;
        margin: 0;
    }
    @include max-screen ($bp-tw) {
        flex-basis: 60%;
        font-size: 1rem;
        padding: .8em;
        text-align: left;
        h1 {
            font-size: 1.2em;
            line-height: 1.3;
        }
    }
}
.post-meta {
    color: #333;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    .cat {
        display: block;
    }
    @include max-screen ($bp-t) {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        .cat {
            margin: 0;
        }
    }
}