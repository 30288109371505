//top section2
.top_main-section2 {
    display: flex;
    order: 2;
    margin: -8em 0 8em;
    @include max-screen ($bp-t) {
        margin: 0 0 7em;
    }
    @include max-screen ($bp-m) {
        margin: 3em 0;
        flex-wrap: wrap;
    }
}
.top_main-section2_half-block-l {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 39em;
    @include max-screen ($bp-tw) {
        width: 60%;
    }
    @include max-screen ($bp-t) {
        width: 100%;
        padding: 3em;
        margin-right: -100%;
        position: relative;
    }
    @include max-screen ($bp-m) {
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
        background: none;
        order: 2;
    }
}
.top_main-section2_half-block-r {
    background-size: cover;
    background-position: center;
    width: 50%;
    @include max-screen ($bp-tw) {
        width: 40%;
    }
    @include max-screen ($bp-t) {
        width: 100%;
    }
    @include max-screen ($bp-m) {
        width: 100%;
        height: 16em;
        order: 1;
    }
}