
$bp-tw: 1064px;
$bp-t: 980px;
$bp-m: 640px;

@mixin max-screen($bp-tw) {
	@media screen and (max-width: bp-tw) {
		@content;
	}
}

@mixin max-screen($bp-t) {
	@media screen and (max-width: bp-t) {
		@content;
	}
}

@mixin max-screen($bp-m) {
  @media screen and (max-width: $bp-m) {
    @content;
  }
}