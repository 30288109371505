//top section3
.top_main-section3 {
    margin: 3em 0;
}
.section3_desc {
    text-align: center;
    font-size: 2.2rem;
    margin: 0em auto 4em;
    @include max-screen ($bp-m) {
        margin: 2em auto;
        font-size: 1.6rem;
        line-height: 1.7;
    }
}
.item {
    display: block;
    &:hover {
        opacity: .75;
    }
}
.item-txt {
    color: #333;
    font-size: 1.6rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 1em .5em;
    @include max-screen ($bp-m) {
        font-size: 1.5rem;
    }
}
.section3_inner-box {
    margin: 3em auto;
    @include max-screen ($bp-m) {
        font-size: 3.4rem;
        line-height: 4rem;
        margin-top: .5em;
        margin-bottom: .5em;
    }
}
.product-thumb {
    position: relative;
    span {
        position: absolute;
        top: 0;
        left: 0;
        background: $theme-color;
        width: 8em;
        color: #fff;
        text-align: center;
        padding: .5em;
    }
    @include max-screen ($bp-m) {
        font-size: 1.6rem;
    }
}
#product-list {
    height: auto;
    figure {
        display: block;
        border-left: 2px solid #fff;
        background-color: #ffffee;
        img {width: 100%;}
        figcaption {
            text-align: center;
            padding: 2rem 0 1em;
            color: #333;
            font-weight: bold;
            line-height: 1.35;
        }
    }
    @include max-screen ($bp-m) {
        figure {
            img {}
            figcaption {
                padding: 2rem 0 0;
                font-size: 2.4rem;
            }
        }
    }
}