//common layout
.layout {
	display: flex;
	justify-content: space-between;
	order: 2;
	margin-top: 6em;
	margin-bottom: 6em;
	@include max-screen ($bp-t) {
		flex-wrap: wrap;
		margin-top: 3em;
		margin-bottom: 2em;
	}
	@include max-screen ($bp-m) {
		margin-top: 0;
		margin-bottom: 0;
	}
}
.layout_col-small {
	flex-basis: 20%;
	margin-right: 5%;
	order: 1;
	@include max-screen ($bp-t) {
		flex-basis: 100%;
		margin-right: 0;
		order: 2;
		aside {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.widget,.bunner_side {
				flex-basis: 47%;
			}
		}
	}
	@include max-screen ($bp-m) {
		aside {
			.widget,.bunner_side {
				flex-basis: 100%;
			}
		}
	}
}
.layout_col-large {
	flex-basis: 75%;
	margin-bottom: 2em;
	order: 2;
	@include max-screen ($bp-t) {
		flex-basis: 100%;
		order: 1;
	}
}
.layout_l-inner {
	padding: 1em 1.5em;
	@include max-screen ($bp-m) {
		padding: 1em;
	}
}

//card layout
.card-layout_3 {
	flex-basis: 33%;
	padding: 1em;
	h3 {
		font-weight: bold;
		min-height: 2em;
	}
	figure {
		.card_img {
			background-color: #eee;
			height: 15em;
			overflow: hidden;
			margin: 1em 0;
			position: relative;
			span {
				color: #fff;
				font-weight: bold;
				position: absolute;
				bottom: 0;
				right: 0;
				background: $theme-color_accent;
				padding: 0 .5em;
			}
		}
	}
	figcaption {
		margin-bottom: 1em;
		min-height: 3em;
		line-height: 1.5;
	}
	@include max-screen ($bp-tw) {
		figure {
			.card_img {
			height: 10em;
			}
		}
	}
	@include max-screen ($bp-t) {
		figure {
			.card_img {
			height: 10em;
			}
		}
	}
	@include max-screen ($bp-m) {
		flex-basis: 100%;
		h3 {
		min-height: initial;
		line-height: 1.6;
		}
		figcaption {
			min-height: initial;
			font-size: 1.6rem;
			line-height: 1.6;
		}
	}
}
.customposts-thumb {
	img {
		width: 100%;
	}
}
