/*
* 	Owl Carousel Owl Demo Theme 
*	v1.3.3
*/

.owl-theme .owl-controls{
	margin-top: 10px;
	text-align: center;
	@include max-screen ($bp-m) {
		text-align: right;
		z-index: 2;
	}
}
.owl-wrapper {
    background-color: #ffe;
}
/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div {
	color: #ec9d00;
	font-size: 3.6rem!important;
	display: inline-block;
	zoom: 1;
	font-size: 12px;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.58);
	width: 50px;
	height: 50px;
	transition: all .2s;
	border: 1px solid;
	&:hover {
		background-color: rgba(51, 51, 51, 0.27);
	}
}
.owl-buttons {
	position: relative;
}
.owl-controls {
	position: absolute;
	top: 8em;
	width: 100%;
	margin: 0;
	@include max-screen ($bp-m) {
		top: 4.7em;
	}
}
.owl-prev {
	position: absolute;
	left: 1%;
	.fa {
		position: relative;
		bottom: 9px;
		left: -2px;
		@include max-screen ($bp-m) {
			bottom: -2px;
			left: -19px;
		}
	}
}
.owl-next {
	position: absolute;
	right: 1%;
	.fa {
		position: relative;
		bottom: 9px;
		right: -3px;
		@include max-screen ($bp-m) {
			bottom: -2px;
			right: 12px;
		}
	}
}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
	text-decoration: none;
}

/* Styling Pagination*/

.owl-theme .owl-controls .owl-page{
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span{
	display: block;
	width: 48px;
	height: 5px;
	margin: 5px 7px;
	filter: Alpha(Opacity=50);
	opacity: .5;
	background: #ffd73f;
	@include max-screen ($bp-tw) {
			width: 80px;
			height: 3px;
		}
	@include max-screen ($bp-m) {
			width: 15px;
			height: 15px;
			border-radius: 50%;
			margin: 5px 7px;
		}
	}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
	height: auto;
	width: auto;
	color: #FFF;
	padding: 2px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}

/* preloading images */
.owl-item.loading{
	min-height: 150px;
	background: url(AjaxLoader.gif) no-repeat center center
}