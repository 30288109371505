//common parts bunner
.banner {
	padding: 0;
	list-style: none;
	flex-wrap: wrap;
	li {
		a {
			display: block;
			&:hover {
				transform: scale(1.1);
				box-shadow: 6px 6px 42px 0px #b5b5b5;
			}
		}
	}
}
.side-bn {
	@extend .banner;
	li {
		margin-bottom: 1.5em;
	}
}
.footer_bn {
	margin-bottom: 6em;
	margin-left: -1em;
	margin-right: -1em;
	@extend .banner;
	li {
		width: 25%;
		padding: 1em;
		margin-bottom: 1em;
	}
	@include max-screen($bp-t) {
		margin-bottom: 5em;
	}
	@include max-screen ($bp-m) {
		margin-bottom: 3em;
		li {
			width: 100%!important;
			margin: 0;
		}
	}
}