//page template contact
.contact {
	h3 {
		margin: 2em 0;
	}
	@include max-screen ($bp-m) {
		h3 {
			margin: 1em 0;
		}
	}
}
.table-contact {
	tbody {
		tr {
			th,td {
				padding: 1em!important;
				vertical-align: middle!important;
			}
			th {
				text-align: center;
				background-color: #f9f9f9;
			}
		}
	}
	@include max-screen ($bp-m) {
		tbody {
			tr {
				th,td {
					display: block;
				}
			}
		}
	}
}
.contact h1 {
	margin: 1em 0;
	@include max-screen ($bp-m) {
		font-size: 2.4rem;
	}
}