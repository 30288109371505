//common footer nav
.footer-nav {
	background: #eee;
	padding: .5em 0;
	nav {
		ul {
			list-style: none;
			padding: 0;
			display: flex;
			justify-content: center;
			margin: auto;
			li {
				margin: 0 0.8rem;
				a {
					color: #333;
					padding: 0 1.2em;
					display: block;
					font-size: 1.4rem;
					&:hover {
						background-color: #ddd;
					}
				}
			}
		}
	}
	@include max-screen($bp-t) {
		nav {
			ul {
				li {
					font-size: 10px;
					a {
						padding: 1em;
					}
				}
			}
		}
	}
}
