//page template archive
.archive-thumb {
	flex-basis: 22%;
	margin-right: 4%;   
	img {
		width: 100%;
	}
	@include max-screen ($bp-m) {
		flex-basis: 37%;
	}
}
.archive-text {
	flex-basis: 74%;
	color: #333;
	h1 {
		font-size: 2.2rem;
		font-weight: bold;
		line-height: 1.3;
	}
	p,time,.cat {
		font-size: 1.2rem;
		line-height: 1.4;
		margin-bottom: 0;
	}
	.cat {
	    background-color: #4bc379;
	    color: #fff;
	    padding: .1em .75em;
	    border-radius: 1em;
	}
	@include max-screen ($bp-m) {
		flex-basis: 59%;
		h1 {
			font-size: 1.2rem;
			line-height: 1.5;
			margin-top: .1em;
			margin-bottom: 0;
		}
		.cat {
			padding: 0;
			color: $theme-color;
			background: none;
		}
	}
}
.archive-box {
	margin-bottom: 1.5em;
	padding: 1em;
	transition: all ease-in-out .2s;
	&:hover {
		background-color: #f3f3f3;
	}
	@include max-screen ($bp-m) {
		padding: 0;
	}
}
.archive-excerpt {
	font-size: 1.6rem;
	margin-top: .5em;
}