//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  // padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
  // margin-bottom: $line-height-computed;
  // list-style: none;
  // background-color: $breadcrumb-bg;
  // border-radius: $border-radius-base;

  > li {
    display: inline;
    padding: 0 0.5em;

    + li:before {
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      // $nbsp: "\00a0";
      // content: "#{$breadcrumb-separator}#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: $breadcrumb-color;
    }
  }

  > .active {
    color: $breadcrumb-active-color;
  }
}
