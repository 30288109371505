//single header
.single-header {
    margin-bottom: 3em;
	@include max-screen ($bp-tw) {
		margin: 0;
	}
}
.single_thumb {
	flex-basis: 36%;
	margin-right: 4%;
	@include max-screen ($bp-tw) {
		flex-basis: 40%;
		height: auto;
		margin-right: 3%;
	}
	@include max-screen ($bp-t) {
		flex-basis: 40%;
	}
	@include max-screen ($bp-m) {
		flex-basis: 100%;
		height: 12em;
	}
}
.post-meta-area {
    justify-content: space-between!important;
    font-size: 1.4rem;
    .cat {
    	margin: 0;
    }
	@include max-screen ($bp-tw) {
		padding: 1em .5em;
	}
	@include max-screen ($bp-m) {
		padding: 0;
	}
}
.tag-list {
    margin-top: .5em;
    font-size: 1.4rem;
    @include max-screen ($bp-tw) {
    	padding: 0 .5em;
    }
}
.sh_inner {
	flex-basis: 60%;
	@include max-screen ($bp-tw) {
		flex-basis: 57%;
	}
	@include max-screen ($bp-m) {
		flex-basis: 100%;
	}
}
.s_header-title {
	font-size: 2.4rem;
	line-height: 1.35;
	@include max-screen ($bp-tw) {
		font-size: 1.8rem;
		margin-bottom: 0;
	}
	@include max-screen ($bp-m) {
		font-size: 1.8rem;
		padding: 0;
		margin-bottom: 0;
	}
}
.bg-grad {
	background: linear-gradient(to top,  rgba(0,0,0,0.57) 0,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 3em;
}
.s-header_inner {
	@include max-screen ($bp-tw) {
		flex-direction: column!important;
	}
}
.single_hr {
	@include max-screen ($bp-tw) {
		margin: 1em 0;
	}
}