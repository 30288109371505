.product-data {
    display: table;
    width: 100%;
    margin-bottom: 1em;
    dl {margin-bottom: 0;}
    @include max-screen ($bp-m) {
        display: block;
    }
}
.data-meta {
    display: table-cell;
    width: 65%;
    padding: 1em 2em;
    dd {
        margin-left: 0;
        padding-bottom: .5em;
        padding-top: .5em;
        border-bottom: 1px dotted #ddd;
    }
    @include max-screen ($bp-m) {
        display: block;
        padding: 1em;
        width: 100%;
    }
}
.data-title {
    width: 30%;
    margin-right: 5%;
    display: table-cell;
    vertical-align: middle;
    background: #0f1131;
    color: #fff;
    text-align: center;
    span {
        font-size: 3rem;
        font-weight: bold;
    }
    @include max-screen ($bp-m) {
        display: block;
        width: 100%;
        h3 {
            margin: 0;
            padding: 1em;
        }
        span {
            font-size: 2.5rem;
        }
    }
}
.product_content {
    margin: 1em auto;
}
//page template products common
.products-wrap {
    display: flex;
    flex-wrap: wrap;
    align-item: baseline;
    @include max-screen ($bp-t) {
        margin-top: 3em;
    }
    @include max-screen ($bp-m) {
    	margin: 0;
    }
}
.item-wrap {
    flex-basis: 25%;
    min-height: 300px;
    margin-bottom: 1em;
    background-color: #f9f9f9;
    a {
        display: block;
        margin: .5em;
        color: #333;
        &:hover {
            background-color: #fff;
            color: $theme_color;
        }
    }
    @include max-screen ($bp-t) {
        flex-basis: 33%;
    }
    @include max-screen ($bp-m) {
        flex-basis: 100%;
    }
}
.products {
    margin: 2em auto;
}
.products-item {
    h2 {
        font-size: 2rem;
        margin: 0;
        padding: 1em .5em;
        text-align: center;
        min-height: 4.9em;
        line-height: 1.4;
    }
    a {
        display: block;
        text-align: center;
        background-color: #fff;
        margin: 1em;
        padding: 1em;
        border-radius: 2px;
        font-size: 1.5rem;
        &:hover {
            background-color: $theme-color;
            color: #fff;
        }
    }
}
.products-thumb {
    figure {
        img {
            width: 100%;
            padding: .5em;
        }
        figcaption {
            padding: 1em 1.5em;
            line-height: 1.6;
            font-size: 1.6rem;
        }
    }
    @include max-screen ($bp-tw) {
        flex-basis: 60%;
        figure {
            figcaption {
                padding: 1em .5em;
                font-size: 1.4rem;
            }
        }
    }
    @include max-screen ($bp-t) {
        flex-basis: 50%;
    }
    @include max-screen ($bp-m) {
    	flex-basis: 100%;
    }
}
.products-data {
    flex-basis: 50%;
    padding: 1em 1em 1em 2em;
    font-size: 1.8rem;
    background-color: #fff;
    margin-left: -5em;
    box-shadow: 8px 8px 12px rgba(221, 221, 221, 0.55);
    dd {
    	margin-bottom: .3em;
    }
    @include max-screen ($bp-tw) {
        flex-basis: 65%;
    }
    @include max-screen ($bp-tw) {
        flex-basis: 67%;
    }
    @include max-screen ($bp-m) {
    	flex-basis: 100%;
		margin: 0;
		padding: 1em 1.5em;
		box-shadow: none;
		dd {
			font-size: 1.4rem;
		}
	}
}
.product-title {
    font-weight: bold;
    margin: .6em 0;
    h1 {
        font-size: 4rem;
    }
    @include max-screen ($bp-m) {
		font-size: 2rem;
		font-weight: 700;
		margin: 1em 0 .5em;
        h1 {
            font-size: 2.4rem;
        }
	}
}
.product-cat {
    background-color: $theme-color;
    width: 265px;
    text-align: center;
    padding: .75em 0;
    border-radius: 30px;
    color: #fff;
    @include max-screen ($bp-m) {
		margin-bottom: 1.4em!important;
	}
}
.products-content {
    max-width: 960px;
    margin: 3em auto 6em;
    text-align: center;
    @include max-screen ($bp-tw) {
    	margin-bottom: 6em;
    	p {
			margin: auto;
    	}
    }
    @include max-screen ($bp-t) {
    	margin-bottom: 6em;
    	p {
			margin: auto;
    	}
    }
    @include max-screen ($bp-m) {
		margin: 1em 0 3em;
    	p {
			font-size: 1.5rem;
			line-height: 1.6;
			width: 100%;
			text-align: left;
    	}
    }
}
.p-content-inner {
    border: 2px solid #f9f9f9;
    margin-bottom: 1em;
    h2 {
        padding: 1em;
        background: #f3f3f3;
        margin: 0;
        color: #f9a622;
        text-align: center;
    }
    img {
        width: 100%;
        height: auto;
        margin-bottom: 2em;
    }
    @include max-screen ($bp-m) {
        h2 {
            padding: .75em;
            font-size: 2rem;
        }
    }
}
.recommend {
    @extend .p-content-inner;
}
.detail {
    @extend .p-content-inner;
    .wp-caption-text {
        margin-top: -2em!important;
        font-size: 1.6rem!important;
        color: #000000!important;
    }
}
.inner-wrap {
    padding: 3em 3em 1em;
    p {
        font-size: 2.2rem;
        line-height: 1.8;
        margin-bottom: 2em;
    }
    h3,h4 {
        font-size: 3rem;
        line-height: 1.6;
        margin-bottom: 1em;
        margin-top: 0;
    }
    @include max-screen ($bp-m) {
        padding: 1em;
        p {
            font-size: 1.6rem;
            margin-bottom: .5em;
            line-height: 1.6;
        }
        h3,h4 {
            font-size: 1.8rem;
            line-height: 1.6;
            text-align: left;
        }
    }
}