//top section4
.bg-title_s4 {
	background-image: url(../../images/sample/section5-sample1.jpg); 
	background-size: cover;
	margin-bottom: 9em;
	@include max-screen ($bp-m) {
		margin-bottom: 0;
	}
}
.section4_achievement-list {
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	list-style: none;
	li {
		flex-basis: 25%;
		position: relative;
		&:after {
			content: " ";
			position: absolute;
			background: rgba(0, 0, 0, 0.30);
			z-index: 1;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
		figure {
			figcaption {
				position: absolute;
				z-index: 2;
				color: #fff;
				text-shadow: 1px 1px 1px #000;
				font-size: 1.8rem;
				width: 100%;
				text-align: center;
				top: 37%;
				padding: 1em;
				font-weight: normal;
			}
		}
	}
	@include max-screen ($bp-tw) {
		align-items: stretch;
		li {
			text-shadow: none;
			flex-basis: 33%;
			&::after {
				position: static;
			}
			figure {
				figcaption  {
					padding: 0 1em;
				    min-height: 4em;
				    position: static;
				    font-size: 1.8rem;
				    color: #333;
				    text-shadow: none;
				    margin: 1em 0;
				}
			}
		}
	}
	@include max-screen ($bp-t) {
		align-items: stretch;
		li {
			text-shadow: none;
			flex-basis: 50%;
			&::after {
				position: static;
			}
			figure {
				figcaption  {
					padding: 0 1em;
				    min-height: 4em;
				    position: static;
				}
			}
		}
	}
	@include max-screen ($bp-m) {
		align-items: baseline;
		margin-top: 3em;
		li {
			flex-basis: 50%;
			figure {
				flex-wrap: wrap;
				figcaption {
					padding: 1em;
					font-size: 1.2rem;
					line-height: 1.4;
				}
			}
		}
	}
}