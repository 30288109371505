//common 
* {
	word-break: break-all;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 100;
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 200;
}

body {
	font-family: $theme-fonts;
	color: $text-color_base;
    font-weight: 200;
    font-size: 1.8rem;
    line-height: 1.8;
    @include max-screen ($bp-m) {
        font-size: 1.5rem;
    }
}
.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}
.position-re {
    position: relative;
}
/* IE10以上 */
@media all and (-ms-high-contrast: none) {
  body {
    font-family: Verdana, Meiryo, sans-serif;
  }
}

a {
	color: $theme-color;
    transition: all .4s ease-in-out;
    cursor: pointer;
	&:hover {
		color: darken($theme-color,25%);
	}
}

.full {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.page-container {
    // margin: 6em auto 0;
    font-size: 2.2rem;
    line-height: 2;
    blockquote {
        font-size: 1.4rem;
        line-height: 1.6;
        margin-bottom: 1em;
        margin-top: 1em;
        background: rgba(255, 255, 255, 0.68);
        color: #544f4f;
    }
    @include max-screen ($bp-tw) {
        margin: 2em auto;
    }
    @include max-screen ($bp-m) {
        margin: 0em auto 1em;
    }
}
.page-inner-block {
    margin: 0em auto 8em;
    @include max-screen ($bp-tw) {
        margin-top: 0em;
    }

    @include max-screen ($bp-t) {
        max-width: 640px;
        margin-bottom: 6em;
        margin-top: 4em;
    }
    @include max-screen ($bp-m) {
        margin-bottom: 2.5em;
        margin-top: 0em;
        width: 100%;
        font-size: 1.6rem;
        line-height: 1.8;
        h3 {
            font-weight: bold;
            font-size: 1.8rem;
        }
        p {
            font-size: 1.6rem;
        }
    }
}

iframe.wp-embedded-content {
    width: 100%!important;
}