//common header topnav
.t-header-nav {
    list-style: none;
    padding-left: 0;
    display: flex;
    justify-content: center;
    width: 960px;
    margin: 2rem auto 3rem;
    transition: .4s ease-in-out;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    @include max-screen ($bp-m) {
		width: 100%;
    }
}
.t-header-nav-item {
    margin: .8rem;
    a {
    	color: #333;
    	padding: 1em;
    	display: block;
    	font-size: 1.4rem;
    	&:hover {
    		background-color: #ddd;
    		color: #333;
    	}
    }
}
