//404 page
.notfound-title-wrap {
    margin-bottom: 2em;
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(51, 51, 51, 0.28);
        top: 0;
        left: 0;
    }
}
.notfound-content {
    width: 70%;
    margin: 5em auto 7em;
}
.notfound-title {
    font-size: 8.6rem;
    color: #Fff;
    text-shadow: 1px 1px 14px #333;
    position: relative;
    z-index: 1;
}
#notfound {
    padding-top: 3em;
}