//top page section common
@import './mixins/mq';
.half-block-inner {
    padding: 2em 6em;;
    h1 {
        font-family: $theme-fonts_mincho;
        color: $theme-color;
        margin-bottom: 1.18em;
        margin-top: 1.6em;
        text-align: center;
        line-height: 1.4;
        font-size: 3.5rem;
    }
    p {
        line-height: 1.8em;
        font-size: 2rem;
    }
    @include max-screen ($bp-tw) {
        padding: 0em 4em;
        h1 {
            font-weight: normal;
            font-size: 3rem;
        }
    }
    @include max-screen ($bp-t) {
        padding: 0em 6em;
        background: rgba(255, 255, 255, 0.94);
    }
    @include max-screen ($bp-m) {
        background: rgba(255, 255, 255, 0.87);
        margin: .75em;
        padding: 1em;
        h1 {
            font-size: 2.4rem;
            line-height: 1.6;
            font-weight: normal;
            margin-top: .5em;
        }
        p {
            font-size: 1em;
        }
    }
}
.bg-title {
    background-size: cover;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-position: center;
    margin-bottom: 7em;
    &:before {
        content: '';
        background: rgba(0, 0, 0, 0.27);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    h1 {
        font-family: $theme-fonts_mincho;
        color: #fff;
        margin-bottom: 1.6em;
        margin-top: 1.6em;
        font-weight: bold;
        text-align: center;
        z-index: 1;
        letter-spacing: 4px;
        text-shadow: 1px 1px 6px #1d1d1d;
    }
    @include max-screen ($bp-tw) {
        height: 10em;
        margin-bottom: 0;
    }
    @include max-screen ($bp-t) {
        margin-bottom: 0;
    }
    @include max-screen ($bp-m) {
        height: auto;
        margin-bottom: 0;
        h1 {
            padding: 1.2em;
            font-size: 2.6rem;
            line-height: 4rem;
            margin: .5em 0;
        }
    }
}
