//top section1
.top_main-section1 {
    display: flex;
    margin: 8em 0;
    @include max-screen ($bp-t) {
        margin-bottom: 0;
        margin-top: 4em;
    }
    @include max-screen ($bp-m) {
        margin: 6em 0  3em;
        flex-wrap: wrap;
    }
}
.top_main-section1_half-block-l {
    background-size: cover;
    background-position: center;
    width: 50%;
    @include max-screen ($bp-tw) {
        width: 40%;
    }
    @include max-screen ($bp-t) {
        width: 100%;
    }
    @include max-screen ($bp-m) {
        width: 100%;
        height: 16em;
    }
}
.top_main-section1_half-block-r {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 39em;
    @include max-screen ($bp-tw) {
        width: 60%;
    }
    @include max-screen ($bp-t) {
        width: 100%;
        padding: 3em;
        margin-left: -100%;
        height: 45em;
    }
    @include max-screen ($bp-m) {
        width: 100%;
        height: auto;
        background: none;
        margin: 0;
        padding: 0;
    }
}