//page template business domain
.bs-color {
    color: #161e2d!important;
    font-weight: bold;
    &::before {
        background-color: #161e2d!important;
    }
    @include max-screen ($bp-m) {
        &::before {
            height: 0!important;
        }
    }
}
.bs-d-top-text {
    margin: 3em 0 5em;
    text-align: center;
    @include max-screen ($bp-tw) {
        margin: 3em 0;
    }
    @include max-screen ($bp-m) {
        margin: 1em auto;
        text-align: left;
        padding: 1em;
        font-size: 1.4rem;
    }
}
.bs-section-title {
    position: relative;
    margin-bottom: 1em;
    font-weight: bold;
    span {
        position: relative;
        left: 2em;
    }
    &::after {
        content: "";
        border-bottom: 1px solid #333;
        width: 1.2em;
        margin: 0 1.5em;
        position: absolute;
        bottom: .5em;
        right: 98%;
    }
    @include max-screen ($bp-tw) {
        span {
            position: relative;
            left: 2em;
        }
        &::after {
            right: 91%;
        }
    }
    @include max-screen ($bp-m) {
        position: static;
        line-height: 1.3;
        font-size: 2.4rem;
    }
}
.bs-section-title_sub {
    text-align: center;
    margin: 6em 0 2em;
    font-weight: bold;
    @include max-screen ($bp-m) {
        margin-top: 2em;
    }
}
.bs-section-title_h3 {
    margin: 1.5em 0;
    @include max-screen ($bp-m) {
        position: static;
        line-height: 1.3;
    }
}
.ds-text {
    margin-bottom: 3.5em;
    margin-top: 3em;
    line-height: 2;
    text-align: center;
    @include max-screen ($bp-tw) {
        width: 90%;
        margin: 1em auto 2em;
    }
    @include max-screen ($bp-t) {
        width: 100%;
    }
    @include max-screen ($bp-m) {
        line-height: normal;
        font-size: 1.4rem;
        text-align: left;
    }
}
.yukimuro-tb {
    margin: 5em 0;
    text-align: center;
    th {
        background-color: #ddd;
        text-align: center;
    }
    td {
        border: 1px solid #eee;
    }
    @include max-screen ($bp-tw) {
        margin: 1em 0;
    }
    @include max-screen ($bp-m) {
        margin: 0 0 1em;
        font-size: 1.4rem;
    }
}
.bs_ul {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: strech;
    flex-wrap: wrap;
    margin-left: -1em;
    margin-right: -1em;
    li {
        flex-basis: 25%;
        padding: 1em;
        figure {
            figcaption {
                padding: .5em 0;
                line-height: 1.5;
                font-size: 1.5rem;
            }
        }
    }
    @include max-screen ($bp-tw) {
        font-size: 1.2rem;
        text-align: left;
    }
    @include max-screen ($bp-m) {
        margin: 0;
        li {
            padding: 0;
            flex-basis: 100%;
            figure {
                figcaption {
                    margin-top: 0;
                }
            }
        }
    }
}
.bs_ul_normal {
    margin: 2em 0;
    li {
        padding: 1em;
    }
    @include max-screen ($bp-m) {
        margin: 1em 0;
        li {
            font-size: 1.6rem;
            line-height: 1.6;
        }
    }
}
.bs-figure {
    margin: 1em 0;
    img {
        margin-bottom: 1em;
    }
    @include max-screen ($bp-m) {
        margin: 0 0 1em;
        figcaption {
            font-size: 1.4rem;
            margin-top: .5em;
        }
    }
}
.bs-bg {
    background-size: 18em;
    background-repeat: no-repeat;
    background-position: bottom right;
}
.bs-s1_bg {
    @extend .bs-bg;
    background-image: url(../../images/page-inner_yacon.jpg); 
    @include max-screen ($bp-m) {
        background-size: 12em;
    }
}
.business-domain {
    flex-wrap: initial;
    margin-bottom: 4em;
    @include max-screen ($bp-tw) {
        margin-bottom: 4em;
    }
    @include max-screen ($bp-t) {
        margin-bottom: 5em;
    }
    @include max-screen ($bp-m) {
        flex-direction: column;
        margin-bottom: 1em;
    }
}
.parts-d-b {
    height: 320px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    &:before {
        content: '';
        background: rgba(0, 0, 0, 0.55);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    p {
        width: 70%;
        font-size: 1.8rem;
        position: relative;
        color: #fff;
        text-align: center;
        margin-bottom: .5em;
        line-height: 1.6;
    }
    a {
        display: block;
    }
    @include max-screen ($bp-m) {
        p {
            font-size: 1.6rem;
            margin-bottom: 1em;
        }
    }
}
.bs-shortcut {
    padding: 0;
    list-style: none;
    margin-bottom: 6em;
    li {
        flex-basis: 33%;
        border: 1px solid #fff;
        a {
            display: block;
            &:hover {
                opacity: .75;
            }
            img {
                width: 100%;
            }
        }
    }
    @include max-screen ($bp-m) {
        display: block;
    }
}