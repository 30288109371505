//common parts pagination
.pagiNation {
	min-width: 320px;
	margin: 4em auto 0;
	text-align: center;
	padding: .5em 0;
	// border-top: 1px solid #e9e9e9;
	// border-bottom: 1px solid #e9e9e9;
	a {
		display: inline-block;
		&:hover {
			background-color: #f0f0f0;
			color: $theme-color;
		}
	}
	.page-numbers {
		padding: 1em .5em;
	}
	.current {
		background-color: $theme-color;
		color: #fff;
	}
	@include max-screen ($bp-m) {
		min-width: 100%;
		padding: 0;
		margin: 0;
	}
} 
.pagination {
	display: block;
	text-align: center;
	span, a {
		padding: .5em 1em;
		margin: 0 4px;
		text-align: center;
		color: #333;
		display: inline-block;
	}
	.current {
		background-color: $theme-color;
		color: #fff;
		border-radius: 2px;
	}
	a {
		&:hover {
			background-color: #eee;
		}
	}
	@include max-screen ($bp-m) {
		margin: 0;
		text-align: center;
		display: block;
		span, a {
			padding: 0 8px;
			text-align: center;
			color: #333;
		}
	}
}