html, body, .perspective {
	width: 100%;
	height: 100%;
}

.perspective {
	background: #aaa;
	position: relative;
}

.container_ppvn {
	background: #fff;
	min-height: 100%;
	position: relative;
	outline: 1px solid rgba(0,0,0,0);
	z-index: 10;
	-webkit-transform: translateZ(0) translateX(0) rotateY(0deg); /* reset transforms (Chrome bug) */
	transform: translateZ(0) translateX(0) rotateY(0deg);
}

.container_ppvn::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 0px;
	opacity: 0;
	background: rgba(0,0,0,0.2);
	/* the transition delay of the height needs to be synced with the container_ppvn transition time */
	-webkit-transition: opacity 0.4s, height 0s 0.4s;
	transition: opacity 0.4s, height 0s 0.4s;
}

.wrapper {
	position: relative;
}

.component {
	margin: 0 auto;
	width: 60%;
	text-align: justify;
	font-size: 1.5em;
}

/* Modal view */
.perspective.modalview {
	position: fixed;
	-webkit-perspective: 1500px;
	perspective: 1500px;
}

.modalview .container_ppvn {
	position: absolute;
	overflow: hidden;
	width: 100%;
	height: 100%;
	cursor: pointer;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	@include max-screen ($bp-m) {
		width: 110vh;
		left: -7em;
	}
}

.modalview .wrapper {
	-webkit-transform: translateZ(-1px); /* solves a rendering bug in Chrome on Windows */
}

.animate .container_ppvn::after {
	opacity: 1;
	height: 101%;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
}

/* Outer Nav */
.outer-nav {
	position: absolute;
	height: auto;
	font-size: 2em;
	display: flex;
	flex-wrap: wrap;
	@include max-screen ($bp-m) {
		flex-direction: column;
	}
}

.outer-nav.vertical {
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.outer-nav.horizontal {
	left: 70%;
	width: 50%!important;
	max-width: 1000px;
	text-align: center;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	overflow: hidden;
	@include max-screen ($bp-m) {
		width: 100%;
		left: 60%;
	}
}

.outer-nav.left {
	left: 25%;
}

.outer-nav.right {
	right: 25%;
}

.outer-nav.top {
	top: 25%;
}

.outer-nav.bottom {
	bottom: 25%;
}

.outer-nav a {
	display: inline-block;
	white-space: nowrap;
	font-weight: 300;
	margin: 0 0 30px 0;
	color: $theme-color;
	font-family: $theme-fonts_mincho;
	transition: color 0.3s;
	transform-style: preserve-3d;
	@include max-screen ($bp-m) {
		text-align: center!important;
		margin: 0;
		font-size: 1.4rem;
	}
}

.outer-nav a:hover {
	color: darken($theme-color,10%);
	background-color: #eee;
}

.outer-nav.vertical a {
	display: block;
}

.outer-nav.horizontal a {
	padding: 15px 30px;
	border-radius: 2px;
	@include max-screen ($bp-m) {
		padding: .5em 0;
	}
}

.outer-nav a::before {
	display: inline-block;
	font-family: 'typicons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	margin-right: 10px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

/* Individual Effects */

/* Effect airbnb */
.effect-airbnb {
	background: #b8b6b4;
}

.effect-airbnb .container_ppvn {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

.effect-airbnb.animate .container_ppvn {
	-webkit-transform: translateZ(-1500px) translateX(100%) rotateY(-45deg);
	transform: translateZ(-1500px) translateX(100%) rotateY(-45deg);
}

.no-csstransforms3d .effect-airbnb.animate .container_ppvn {
	left: 75%;
}

.effect-airbnb .outer-nav a {
	opacity: 0;
	-webkit-transform: translateX(-150px);
	transform: translateX(-150px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.effect-airbnb.animate .outer-nav a {
	opacity: 1;
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.effect-airbnb.animate .outer-nav a:nth-child(2) {
	-webkit-transition-delay: 0.04s;
	transition-delay: 0.04s;
}

.effect-airbnb.animate .outer-nav a:nth-child(3) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-airbnb.animate .outer-nav a:nth-child(4) {
	-webkit-transition-delay: 0.12s;
	transition-delay: 0.12s;
}

.effect-airbnb.animate .outer-nav a:nth-child(5) {
	-webkit-transition-delay: 0.16s;
	transition-delay: 0.16s;
}

.effect-airbnb.animate .outer-nav a:nth-child(6) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.effect-airbnb.animate .outer-nav a:nth-child(7) {
	-webkit-transition-delay: 0.24s;
	transition-delay: 0.24s;
}

/* Effect Move Left */
.effect-moveleft {
	background: #f4f3f1;
}

.effect-moveleft .container_ppvn {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

.effect-moveleft .container_ppvn::after {
	background: rgba(255,255,255,0.6);
}

.effect-moveleft.animate .container_ppvn {
	-webkit-transform: translateX(-50%) rotateY(45deg) translateZ(-50px);
	transform: translateX(-50%) rotateY(45deg) translateZ(-50px);
}

.no-csstransforms3d .effect-moveleft.animate .container_ppvn {
	left: -75%;
}

.effect-moveleft .outer-nav a {
	color: #e86a32;
	opacity: 0;
	-webkit-transform: translateX(100px) translateZ(-1000px);
	transform: translateX(100px) translateZ(-1000px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.effect-moveleft .outer-nav a:hover {
	color: #333;
}

.effect-moveleft.animate .outer-nav a {
	opacity: 1;
	-webkit-transform: translateX(0) translateZ(0);
	transform: translateX(0) translateZ(0);
}

.effect-moveleft.animate .outer-nav a:nth-child(2) {
	-webkit-transition-delay: 0.04s;
	transition-delay: 0.04s;
}

.effect-moveleft.animate .outer-nav a:nth-child(3) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-moveleft.animate .outer-nav a:nth-child(4) {
	-webkit-transition-delay: 0.12s;
	transition-delay: 0.12s;
}

.effect-moveleft.animate .outer-nav a:nth-child(5) {
	-webkit-transition-delay: 0.16s;
	transition-delay: 0.16s;
}

.effect-moveleft.animate .outer-nav a:nth-child(6) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.effect-moveleft.animate .outer-nav a:nth-child(7) {
	-webkit-transition-delay: 0.24s;
	transition-delay: 0.24s;
}

/* Effect Rotate Left */
.effect-rotateleft {
	background: #e96e4f;
}

.effect-rotateleft .container_ppvn {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
}

.effect-rotateleft .container_ppvn::after {
	background: rgba(255,255,255,0.6);
}

.effect-rotateleft.animate .container_ppvn {
	-webkit-transform: translateZ(-1800px) translateX(-50%) rotateY(45deg);
	transform: translateZ(-1800px) translateX(-50%) rotateY(45deg);
}

.no-csstransforms3d .effect-rotateleft.animate .container_ppvn {
	left: -75%;
}

.effect-rotateleft .outer-nav a {
	opacity: 0;
	-webkit-transform: translateX(350px) translateZ(-1000px);
	transform: translateX(350px) translateZ(-1000px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.effect-rotateleft.animate .outer-nav a {
	opacity: 1;
	-webkit-transform: translateX(0) translateZ(0);
	transform: translateX(0) translateZ(0);
}

.effect-rotateleft.animate .outer-nav a:nth-child(2) {
	-webkit-transition-delay: 0.04s;
	transition-delay: 0.04s;
}

.effect-rotateleft.animate .outer-nav a:nth-child(3) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-rotateleft.animate .outer-nav a:nth-child(4) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-rotateleft.animate .outer-nav a:nth-child(5) {
	-webkit-transition-delay: 0.16s;
	transition-delay: 0.16s;
}

.effect-rotateleft.animate .outer-nav a:nth-child(6) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.effect-rotateleft.animate .outer-nav a:nth-child(7) {
	-webkit-transition-delay: 0.24s;
	transition-delay: 0.24s;
}

/* Effect Move Down */
.effect-movedown {
	background: #34495e;
}

.effect-movedown .container_ppvn {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

.effect-movedown .container_ppvn::after {
	background: rgba(52, 73, 94, 0.5);
}

.effect-movedown.animate .container_ppvn {
	-webkit-transform: translateY(100%) translateZ(-1500px);
	transform: translateY(100%) translateZ(-1500px);
}

.no-csstransforms3d .effect-movedown.animate .container_ppvn {
	top: 75%;
}

.effect-movedown .outer-nav a {
	opacity: 0;
	-webkit-transform: translateY(-20px);
	transform: translateY(-20px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.effect-movedown .outer-nav a:hover {
	color: #ed8151;
}

.effect-movedown.animate .outer-nav a {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

.effect-movedown.animate .outer-nav a:nth-child(2) {
	-webkit-transition-delay: 0.04s;
	transition-delay: 0.04s;
}

.effect-movedown.animate .outer-nav a:nth-child(3) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-movedown.animate .outer-nav a:nth-child(4) {
	-webkit-transition-delay: 0.12s;
	transition-delay: 0.12s;
}

.effect-movedown.animate .outer-nav a:nth-child(5) {
	-webkit-transition-delay: 0.16s;
	transition-delay: 0.16s;
}

.effect-movedown.animate .outer-nav a:nth-child(6) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.effect-movedown.animate .outer-nav a:nth-child(7) {
	-webkit-transition-delay: 0.24s;
	transition-delay: 0.24s;
}

/* Effect Rotate Top */
.effect-rotatetop {
	background: #edcdbb;
}

.effect-rotatetop .container_ppvn {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

.effect-rotatetop .container_ppvn::after {
	background: rgba(94,59,43,0.6);
}

.effect-rotatetop.animate .container_ppvn {
	-webkit-transform: translateZ(-1500px) translateY(-50%) rotateX(-45deg);
	transform: translateZ(-1500px) translateY(-50%) rotateX(-45deg);
}

.no-csstransforms3d .effect-rotatetop.animate .container_ppvn {
	top: -75%;
}

.effect-rotatetop .outer-nav a {
	opacity: 0;
	color: #ed8151;
	-webkit-transform: translateY(200px) translateZ(-1000px);
	transform: translateY(200px) translateZ(-1000px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.effect-rotatetop .outer-nav a:hover {
	color: #777;
}

.effect-rotatetop.animate .outer-nav a {
	opacity: 1;
	-webkit-transform: translateY(0) translateZ(0);
	transform: translateY(0) translateZ(0);
}

.effect-rotatetop.animate .outer-nav a:nth-child(2) {
	-webkit-transition-delay: 0.04s;
	transition-delay: 0.04s;
}

.effect-rotatetop.animate .outer-nav a:nth-child(3) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-rotatetop.animate .outer-nav a:nth-child(4) {
	-webkit-transition-delay: 0.12s;
	transition-delay: 0.12s;
}

.effect-rotatetop.animate .outer-nav a:nth-child(5) {
	-webkit-transition-delay: 0.16s;
	transition-delay: 0.16s;
}

.effect-rotatetop.animate .outer-nav a:nth-child(6) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.effect-rotatetop.animate .outer-nav a:nth-child(7) {
	-webkit-transition-delay: 0.24s;
	transition-delay: 0.24s;
}

/* Effect Lay Down */
.effect-laydown {
	background: #fff;
}

.effect-laydown .container_ppvn {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform-origin: 50% 150%;
	transform-origin: 50% 150%;
}

.effect-laydown.animate .container_ppvn {
	-webkit-transform: translateZ(-1500px) rotateX(80deg);
	transform: translateZ(-1500px) rotateX(80deg);
}

.no-csstransforms3d .effect-laydown.animate .container_ppvn {
	top: 75%;
}

.effect-laydown .outer-nav a {
	opacity: 0;
	-webkit-transform: translateY(-200px) translateZ(-1000px);
	transform: translateY(-200px) translateZ(-1000px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.effect-laydown.animate .outer-nav a {
	opacity: 1;
	-webkit-transform: translateY(0) translateZ(0);
	transform: translateY(0) translateZ(0);
}

.effect-laydown.animate .outer-nav a:nth-child(7){
	-webkit-transition-delay: 0.0s;
	transition-delay: 0.0s;
}

.effect-laydown.animate .outer-nav a:nth-child(6) {
	-webkit-transition-delay: 0.04s;
	transition-delay: 0.04s;
}

.effect-laydown.animate .outer-nav a:nth-child(5) {
	-webkit-transition-delay: 0.08s;
	transition-delay: 0.08s;
}

.effect-laydown.animate .outer-nav a:nth-child(4) {
	-webkit-transition-delay: 0.12s;
	transition-delay: 0.12s;
}

.effect-laydown.animate .outer-nav a:nth-child(3) {
	-webkit-transition-delay: 0.16s;
	transition-delay: 0.16s;
}

.effect-laydown.animate .outer-nav a:nth-child(2) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.effect-laydown.animate .outer-nav a:first-child {
	-webkit-transition-delay: 0.24s;
	transition-delay: 0.24s;
}

/* Media Queries */
@media screen and (max-width: 77em) {
	
	.outer-nav.top {
		top: 15%;
	}

	.outer-nav.bottom {
		bottom: 15%;
	}
}

@media screen and (max-width: 36.625em), screen and (max-height: 41.75em) {

	.outer-nav.top {
		top: 12%;
	}

	.outer-nav.bottom {
		bottom: 5%;
	}
	
	.outer-nav.horizontal {
		font-size: 1.7em;
		width: 6.2em;
	}

	.outer-nav.horizontal a {
		display: block;
		text-align: left;
	}

	/* Special Case */
	.effect-rotatetop .outer-nav.horizontal {
		width: 95%;
		bottom: auto;
		top: 50%;
	}

	.effect-rotatetop .outer-nav.horizontal a {
		display: inline-block;
	}

}


@media screen and (max-width: 31em), screen and (max-height: 36.2em) {
	.outer-nav.horizontal,
	.outer-nav.vertical {
		font-size: 1.2em;
		width: 6.8em;
	}

	.outer-nav.right {
		right: auto;
		left: 50%;
	}
}

@media screen and (max-height: 31.6em) {
	.outer-nav a {
		margin-bottom: 20px;
	}
}